import { Box, Container, Toolbar } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";
import Head from "next/head";
import { useState } from "react";
import ResponsiveDrawer from "./ResponsiveDrawer";
import SearchNavBar from "./SearchNavBar";

function PageLayout({
  children,
  title = "Rex Clinical Tools",
  additionalDrawerItems,
  // We have to be able to merge/spread sx property into existing sx container property, otherwise it will overwrite it and cause issues with our layout.
  containerProps = {},
  breadcrumbs,
  utilityToolbarItems,
  utilityToolbarProps = {},
}: {
  children: any;
  title?: string;
  additionalDrawerItems?: any[];
  containerProps?: {
    sx?: any;
    [x: string]: any;
  };
  breadcrumbs?: { href: string; title: string; shortTitle?: string }[];
  utilityToolbarItems?: any[];
  utilityToolbarProps?: any;
}) {
  const drawerWidth = "240px";
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <ConfirmProvider>
      <Head>
        <title>{title}</title>
      </Head>
      <Box sx={{ flexGrow: 1 }}>
        <ResponsiveDrawer
          drawerWidth={drawerWidth}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          additionalDrawerItems={additionalDrawerItems}
        />
        <SearchNavBar
          toggleDrawer={toggleDrawer}
          title={title}
          breadcrumbs={breadcrumbs}
        />
        {utilityToolbarItems && (
          <Toolbar
            variant="dense"
            disableGutters
            sx={{
              zIndex: 10,
              backgroundColor: (theme) => theme.palette.grey[100],
              width: {
                xs: "100%",
                md: `calc(100% - ${drawerWidth})`,
              },
              left: { xs: 0, md: drawerWidth },
              position: "fixed",
            }}
            {...utilityToolbarProps}
          >
            {utilityToolbarItems}
          </Toolbar>
        )}
        <Box
          component="main"
          // Apply left margin to compensate for the fixed drawer on medium and above viewport breakpoints.
          sx={{
            paddingTop: utilityToolbarItems ? 6 : 0,
            height: {
              xs: "calc(100% - 104px)",
              sm: "calc(100% - 112px)",
            },
            marginLeft: { xs: 0, md: drawerWidth },
            flexGrow: 1,
          }}
        >
          <Container
            sx={{
              height: "100%",
              // This will allow the user of PageLayout to override any of the sx/style properties defined above.
              ...containerProps?.sx,
            }}
            {...containerProps}
          >
            {children}
          </Container>
        </Box>
      </Box>
    </ConfirmProvider>
  );
}

export default PageLayout;
