import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import * as React from "react";

export default function Breadcrumbs({ breadcrumbs }) {
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));

  if (!breadcrumbs) {
    return null;
  }

  return (
    <Toolbar variant="dense">
      <Box role="presentation">
        <MuiBreadcrumbs
          aria-label="breadcrumb"
          sx={{
            "& .MuiBreadcrumbs-separator": {
              color: "white",
            },
          }}
        >
          {breadcrumbs.map((breadcrumb, index) => {
            if (index === breadcrumbs.length - 1) {
              return (
                <Typography
                  color="white"
                  fontWeight="bold"
                  fontSize={{ xs: 14, sm: 15, md: 16 }}
                  key={breadcrumb.href}
                >
                  {/* If this is an XS size screen, e.g., mobile device, then we want to show shortened versions of the breadcrumb titles if available. This will fallback to the normal title if the shortened title isn't defined. */}
                  {isExtraSmallScreen
                    ? breadcrumb.shortTitle ?? breadcrumb.title
                    : breadcrumb.title}
                </Typography>
              );
            }
            return (
              <Link
                href={breadcrumb.href}
                key={breadcrumb.href}
                underline="always"
                color="#fff"
                fontSize={{ xs: 14, sm: 15, md: 16 }}
              >
                {/* If this is an XS size screen, e.g., mobile device, then we want to show shortened versions of the breadcrumb titles if available. This will fallback to the normal title if the shortened title isn't defined. */}
                {isExtraSmallScreen
                  ? breadcrumb.shortTitle ?? breadcrumb.title
                  : breadcrumb.title}
              </Link>
            );
          })}
        </MuiBreadcrumbs>
      </Box>
    </Toolbar>
  );
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      shortTitle: PropTypes.string,
    })
  ),
};
