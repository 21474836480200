import { PersonAdd as PersonAddIcon } from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import Link from "next/link";
import PropTypes from "prop-types";

function PrescribersDrawerItems({ useDense }) {
  return (
    <List dense={useDense}>
      <ListSubheader>Actions</ListSubheader>
      <Link href="/prescribers/add-new-prescriber" passHref legacyBehavior>
        <ListItemButton>
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary="Add New Prescriber" />
        </ListItemButton>
      </Link>
    </List>
  );
}

PrescribersDrawerItems.propTypes = {
  useDense: PropTypes.bool,
};

PrescribersDrawerItems.defaultProps = {
  useDense: false,
};

export default PrescribersDrawerItems;
