import { Add as AddIcon } from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import Link from "next/link";
import PropTypes from "prop-types";

function MedicationsDrawerItems({ useDense }) {
  return (
    <List dense={useDense}>
      <ListSubheader>Actions</ListSubheader>
      <Link href="/medications/add-new-medication" passHref legacyBehavior>
        <ListItemButton>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Add New Medication" />
        </ListItemButton>
      </Link>
    </List>
  );
}

MedicationsDrawerItems.propTypes = {
  useDense: PropTypes.bool,
};

MedicationsDrawerItems.defaultProps = {
  useDense: false,
};

export default MedicationsDrawerItems;
