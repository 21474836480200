import { Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  Toolbar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import algoliasearch from "algoliasearch/lite";
import MaxSizeLoading from "components/MaxSizeLoading";
import signOut from "lib/client/signOut";
import { signIn, useSession } from "next-auth/react";
import PropTypes from "prop-types";
import * as React from "react";
import { InstantSearch } from "react-instantsearch";
import Breadcrumbs from "./Breadcrumbs";
import SearchModal from "./SearchModal";

// configure and initiate algoliasearch connection
const searchClient = algoliasearch(
  "8PZ436A5AB",
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY
);

const ColorButton = styled(Button)({
  color: "black",
  borderColor: "rgb(190, 190, 190)",
  backgroundColor: "white",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgb(245, 245, 245)",
    borderColor: "rgb(190, 190, 190)",
  },
});

function SearchNavBar({ toggleDrawer, title, breadcrumbs }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { status, data: session } = useSession({
    required: true,
    onUnauthenticated() {
      signIn("google");
    },
  });

  if (status === "loading") {
    return <MaxSizeLoading />;
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        paddingRight: "0 !important",
        zIndex: (theme) => ({
          md: theme.zIndex.drawer + 1,
        }),
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          edge="start"
          sx={{
            marginRight: { xs: 1, sm: 2 },
            marginLeft: { xs: -1 },
            display: { xs: "flex", md: "none" },
          }}
        >
          <MenuIcon sx={{ fontSize: 28 }} />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          display={{ xs: "none", md: "block" }}
        >
          {title}
        </Typography>
        <InstantSearch
          searchClient={searchClient}
          future={{ preserveSharedStateOnUnmount: true }}
          indexName="patients"
        >
          <SearchModal />
        </InstantSearch>
        {status !== "loading" ? (
          <Box display="flex" alignItems="center" marginLeft="auto">
            <IconButton
              onClick={handleMenu}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              sx={{ padding: 0 }}
            >
              <Avatar src={session.user.image} color="inherit" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{
                "& .MuiMenu-paper": {
                  width: "320px",
                  maxWidth: "100%",
                },
                "& .MuiList-root": {
                  padding: 0,
                },
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                paddingX={2}
                paddingY={4}
                alignItems="center"
              >
                <Avatar
                  src={session.user.image}
                  color="inherit"
                  sx={{ marginBottom: 2, height: 48, width: 48 }}
                />
                <Typography variant="h6" fontWeight="bold">
                  {session.user.name}
                </Typography>
                <Typography color="gray">{session.user.email}</Typography>
                <ColorButton
                  variant="outlined"
                  disabled
                  sx={{ borderRadius: 4, marginTop: 2 }}
                >
                  Manage Settings
                </ColorButton>
              </Box>
              <Divider />
              <Box
                paddingX={2}
                paddingY={3}
                display="flex"
                justifyContent="center"
              >
                <ColorButton
                  fullWidth
                  size="large"
                  variant="outlined"
                  onClick={async () => {
                    await signOut();
                  }}
                >
                  Sign Out
                </ColorButton>
              </Box>
            </Menu>
          </Box>
        ) : (
          <MaxSizeLoading />
        )}
      </Toolbar>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </AppBar>
  );
}

SearchNavBar.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  title: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};
export default SearchNavBar;
