import { timeZone } from "config/timeZone";
import { formatInTimeZone } from "date-fns-tz";
import { ensureValueIsDate } from "lib/shared/ensureValueIsDate";
import { Administration } from "models/Administration";
import { Margins, TDocumentDefinitions } from "pdfmake/interfaces";

export function createAdministrationDocDefinition(
  administration: Administration,
  showPrescriptionNumberAndBillingInfo: boolean = false
): TDocumentDefinitions {
  return {
    content: [
      {
        text: "Injection Administration Record",
        fontSize: 16,
        bold: true,
        alignment: "center",
        margin: [0, 0, 0, 20],
      },
      {
        text: "Patient Information",
        style: "sectionHeader",
      },
      {
        columns: [
          {
            width: "*",
            text: "Name",
            style: "columnHeader",
          },
          {
            width: "*",
            text: "Birthdate",
            style: "columnHeader",
          },
          {
            width: "*",
            text: "Gender",
            style: "columnHeader",
          },
        ],
        // optional space between columns
        columnGap: 10,
      },
      {
        columns: [
          {
            width: "*",
            text: `${administration.prescription.patient.firstName} ${administration.prescription.patient.lastName}`,
          },
          {
            width: "*",
            text: formatInTimeZone(
              ensureValueIsDate(administration.prescription.patient.birthdate),
              timeZone,
              "P"
            ),
          },
          {
            width: "*",
            text: administration.prescription.patient.gender,
          },
        ],
        // optional space between columns
        columnGap: 10,
        margin: [0, 0, 0, 25],
      },
      {
        text: "Prescription Information",
        style: "sectionHeader",
      },
      {
        columns: [
          {
            width: "*",
            text: "Medication",
            style: "columnHeader",
          },
          {
            width: "*",
            text: "Dose and Frequency",
            style: "columnHeader",
          },
          {
            width: "*",
            text: "Prescriber",
            style: "columnHeader",
          },
        ],
        // optional space between columns
        columnGap: 10,
      },
      {
        columns: [
          {
            width: "*",
            text: `${administration.prescription.medication.name} ${administration.prescription.medication.strength}`,
          },
          {
            width: "*",
            text:
              administration.prescription.orderType === "recurring"
                ? `${administration.prescription.doseAmount} ${administration.prescription.doseUnits} every ${administration.prescription.frequencyValue} ${administration.prescription.frequencyUnits}`
                : `${administration.prescription.doseAmount} ${administration.prescription.doseUnits} one time only`,
          },
          {
            width: "*",
            text: `${administration.prescription.prescriber.firstName} ${administration.prescription.prescriber.lastName}`,
          },
        ],
        // optional space between columns
        columnGap: 10,
        margin: [0, 0, 0, 25],
      },
      ...(showPrescriptionNumberAndBillingInfo
        ? [
            {
              columns: [
                {
                  width: "*",
                  text: "Prescription Number",
                  style: "columnHeader",
                },
                {
                  width: "*",
                  text: "Date Billed",
                  style: "columnHeader",
                },
                { width: "*", text: "" },
              ],
              // optional space between columns
              columnGap: 10,
            },
            {
              columns: [
                {
                  width: "*",
                  text: administration.prescriptionNumber as string,
                },
                {
                  width: "*",
                  text: administration.lastBilledDate
                    ? formatInTimeZone(
                        ensureValueIsDate(administration.lastBilledDate),
                        timeZone,
                        "P"
                      )
                    : "",
                },
                {
                  width: "*",
                  text: "",
                },
              ],
              // optional space between columns
              columnGap: 10,
              margin: [0, 0, 0, 25] as Margins,
            },
          ]
        : []),

      {
        text: "Administration Information",
        style: "sectionHeader",
      },
      {
        columns: [
          {
            width: "*",
            text: "Date and Time",
            style: "columnHeader",
          },
          {
            width: "*",
            text: "Injection Site",
            style: "columnHeader",
          },
          {
            width: "*",
            text: "Administrator",
            style: "columnHeader",
          },
        ],
        // optional space between columns
        columnGap: 10,
      },
      {
        columns: [
          {
            width: "*",
            text: formatInTimeZone(
              ensureValueIsDate(administration.administrationDate),
              timeZone,
              "Pp"
            ),
          },
          {
            width: "*",
            text: administration.administrationSite,
          },
          {
            width: "*",
            text: administration.administeredBy,
          },
        ],
        // optional space between columns
        columnGap: 10,
        margin: [0, 0, 0, 25],
      },
      {
        columns: [
          {
            width: "*",
            text: "Next Administration Due",
            style: "columnHeader",
          },
          {
            width: "*",
            text: "Product Lot Number",
            style: "columnHeader",
          },
          {
            width: "*",
            text: "Product Expiration Date",
            style: "columnHeader",
          },
        ],
        // optional space between columns
        columnGap: 10,
      },
      {
        columns: [
          {
            width: "*",
            text: administration.injectionNowDue
              ? formatInTimeZone(
                  ensureValueIsDate(administration.injectionNowDue),
                  timeZone,
                  "P"
                )
              : "Not Applicable",
          },
          {
            width: "*",
            text: administration.productLotNumber,
          },
          {
            width: "*",
            text: formatInTimeZone(
              ensureValueIsDate(administration.productExpirationDate),
              timeZone,
              "P"
            ),
          },
        ],
        // optional space between columns
        columnGap: 10,
        margin: [0, 0, 0, 25],
      },
      {
        columns: [
          {
            width: "*",
            text:
              administration.receivingSignatureType === "Patient"
                ? "Patient Signature"
                : "Caregiver Signature",
            style: "columnHeader",
          },
          {
            width: "*",
            text:
              administration.receivingSignatureType === "Patient"
                ? ""
                : "Caregiver Name",
            style: "columnHeader",
          },
          {
            width: "*",
            text: "",
            style: "columnHeader",
          },
        ],
        // optional space between columns
        columnGap: 10,
      },
      {
        columns: [
          {
            image: administration.receivingSignature,
            width: 150,
          },
          {
            width: "*",
            text:
              administration.receivingSignatureType === "Patient"
                ? ""
                : (administration.caregiverName as string),
          },
          {
            width: "*",
            text: "",
          },
        ],
        // optional space between columns
        columnGap: 10,
        margin: [0, 0, 0, 25],
      },
    ],
    styles: {
      sectionHeader: {
        bold: true,
        decoration: "underline",
        margin: [0, 0, 0, 10],
      },
      columnHeader: {
        margin: [0, 0, 0, 5],
        bold: true,
      },
    },
  };
}
