/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";

export default function CustomSvgIcon({ sx = [], svgFile }) {
  return (
    <SvgIcon
      fontSize="small"
      component={svgFile}
      viewBox="0 0 512 512"
      sx={[
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
}
