export enum TaskStatus {
  Pending = "Pending",
  Failed = "Failed",
  Completed = "Completed",
  Inapplicable = "Inapplicable",
}

export type DateQueryOperators = "!=" | "<" | "<=" | "==" | ">" | ">=";

export interface DateQueryOptions {
  operator: DateQueryOperators;
  value: Date;
}
