import { PersonAdd as PersonAddIcon } from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";

import Link from "next/link";

function PatientsDrawerItems({ useDense }) {
  return (
    <List dense={useDense}>
      <ListSubheader>Actions</ListSubheader>
      <Link href="/patients/add-new-patient" passHref legacyBehavior>
        <ListItemButton>
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary="Add New Patient" />
        </ListItemButton>
      </Link>
    </List>
  );
}

export default PatientsDrawerItems;
