import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { createPrescriptionLink } from "models/Prescription";
import Link from "next/link";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { Highlight, useHits } from "react-instantsearch";

function PrescriptionsHitsList({ closeSearchModal }) {
  const { hits } = useHits();
  return (
    <List disablePadding>
      {hits.length ? (
        hits.map((hit) => {
          return (
            <ListItem
              key={hit.objectID}
              disablePadding
              sx={{
                borderBottom: "1px solid rgb(224, 227, 231)",
              }}
            >
              <Link
                href={createPrescriptionLink(hit.patient.id, hit.objectID)}
                legacyBehavior
              >
                <ListItemButton component="a" onClick={closeSearchModal}>
                  <ListItemText
                    primary={
                      <Fragment>
                        <Highlight hit={hit} attribute="medication.name" />{" "}
                        <Highlight hit={hit} attribute="medication.strength" />{" "}
                        {hit.orderType === "one-time"
                          ? "one time only"
                          : `every ${hit.frequencyValue} ${hit.frequencyUnits}`}
                      </Fragment>
                    }
                    primaryTypographyProps={{
                      fontSize: "14px",
                      sx: {
                        color: (theme) =>
                          hit.status === "active"
                            ? theme.palette.success.dark
                            : theme.palette.error.dark,
                      },
                    }}
                    secondary={
                      <Fragment>
                        <Typography display="inline-block" variant="body">
                          Patient:
                        </Typography>{" "}
                        <Highlight hit={hit} attribute="patient.firstName" />{" "}
                        <Highlight hit={hit} attribute="patient.lastName" />
                        {" - "}
                        <Typography display="inline-block" variant="body">
                          Patient DOB:
                        </Typography>{" "}
                        <Highlight
                          hit={hit}
                          attribute="patient.birthdateFormattedString"
                        />
                        <br />
                        <Typography display="inline-block" variant="body">
                          Status:{" "}
                          {hit?.status?.charAt(0)?.toUpperCase() +
                            hit?.status?.slice(1)}
                        </Typography>
                        {" - "}
                        <Typography display="inline-block" variant="body">
                          Next Injection:{" "}
                          {hit?.nextInjectionDateFormattedString}
                        </Typography>
                        <br />
                        <Typography display="inline-block" variant="body">
                          Prescriber: {hit?.prescriber.firstName}{" "}
                          {hit?.prescriber.lastName}
                        </Typography>
                        {hit?.copay && (
                          <Fragment>
                            {" - "}
                            <Typography
                              display="inline-block"
                              variant="body"
                              fontWeight="bold"
                              sx={{ textDecoration: "underline" }}
                            >
                              Copay: {hit?.copay}
                            </Typography>
                          </Fragment>
                        )}
                      </Fragment>
                    }
                    secondaryTypographyProps={{
                      fontSize: "12px",
                      sx: {
                        color: (theme) =>
                          hit.status === "active"
                            ? theme.palette.success.dark
                            : theme.palette.error.dark,
                      },
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          );
        })
      ) : (
        <ListItem>
          <ListItemText
            primary="No matching prescriptions..."
            primaryTypographyProps={{ fontSize: "14px" }}
          />
        </ListItem>
      )}
    </List>
  );
}

PrescriptionsHitsList.propTypes = {
  closeSearchModal: PropTypes.func.isRequired,
};

export default PrescriptionsHitsList;
