import { captureException } from "@sentry/nextjs";
import axios from "axios";
import { auth, db } from "config/firebaseClient";
import { format } from "date-fns";
import {
  Timestamp,
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
  serverTimestamp,
} from "firebase/firestore";
import { createAdministrationDocDefinition } from "lib/shared/createAdministrationDocDefinition";
import { ensureValueIsDate } from "lib/shared/ensureValueIsDate";
import {
  Prescription,
  calculateUpdatedNextInjectionDate,
} from "models/Prescription";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useEffect } from "react";
import sanitize from "sanitize-filename";
import { NotificationStatus } from "./Notification";
import { Patient } from "./Patient";
import { DateQueryOptions, TaskStatus } from "./types";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export async function addAdministration({
  patient,
  prescription,
  administrationDate,
  administeredBy,
  administrationComments,
  administrationSite,
  billingPharmacy,
  needleLength,
  overrideSourceOrBillingPharmacy,
  productExpirationDate,
  productLotNumber,
  receivingSignature,
  receivingSignatureType,
  sourcePharmacy,
  caregiverName,
}: {
  patient: Patient;
  prescription: Prescription;
  administrationDate: Date;
  administeredBy: string;
  administrationComments: string;
  administrationSite: string;
  billingPharmacy: string;
  needleLength: string;
  overrideSourceOrBillingPharmacy: boolean;
  productExpirationDate: Date;
  productLotNumber: string;
  receivingSignature: string;
  receivingSignatureType: string;
  sourcePharmacy: string;
  caregiverName?: string;
}) {
  const batch = writeBatch(db);

  let updatedNextInjectionDate = null;

  if (prescription.orderType === "recurring") {
    updatedNextInjectionDate = calculateUpdatedNextInjectionDate(
      administrationDate,
      prescription.frequencyValue,
      prescription.frequencyUnits,
    );
  }

  // Create the new administration
  const newAdministrationRef = doc(
    collection(db, "patients", patient.id, "administrations"),
  );

  const newAdministrationData = {
    id: newAdministrationRef.id,
    patientId: patient.id,
    prescriptionId: prescription.id,
    prescription: {
      patient: {
        firstName: prescription.patient.firstName,
        lastName: prescription.patient.lastName,
        birthdate: prescription.patient.birthdate,
        gender: prescription.patient.gender,
        id: prescription.patient.id,
      },
      prescriber: {
        firstName: prescription.prescriber.firstName,
        lastName: prescription.prescriber.lastName,
        NPI: prescription.prescriber.NPI,
        email: prescription.prescriber.email,
        fax: prescription.prescriber.fax,
        notificationPrefs: prescription.prescriber.notificationPrefs,
        id: prescription.prescriber.id,
      },
      medication: {
        name: prescription.medication.name,
        strength: prescription.medication.strength,
        id: prescription.medication.id,
      },
      orderType: prescription.orderType,
      doseAmount: prescription.doseAmount,
      doseUnits: prescription.doseUnits,
      frequencyValue: prescription.frequencyValue,
      frequencyUnits: prescription.frequencyUnits,
      id: prescription.id,
    },
    createdAt: new Date(),
    createdBy: {
      uid: auth?.currentUser?.uid,
      name: auth?.currentUser?.displayName,
    },
    administeredBy,
    administrationDate,
    administrationComments,
    administrationSite,
    billingPharmacy,
    needleLength,
    overrideSourceOrBillingPharmacy,
    productExpirationDate,
    productLotNumber,
    receivingSignature,
    receivingSignatureType,
    // only pass caregiverName if truthy, i.e., not ""
    ...(Boolean(caregiverName) && { caregiverName }),
    sourcePharmacy,
    injectionWasDue: prescription.nextInjectionDate,
    injectionNowDue: updatedNextInjectionDate,
    appointmentDateTime: prescription.appointmentDateTime ?? null,
    appointmentComments: prescription.appointmentComments ?? null,
    appointmentServiceLocation: prescription.appointmentServiceLocation ?? null,
    // These will be updated on the server side once completed. We will then run a process each night that checks for false values on any of these and warns the users.
    calendarEventUpdate: { status: TaskStatus.Pending },
    calendarEventCreation: { status: TaskStatus.Pending },
    emailNotifications: { status: NotificationStatus.Pending },
    faxNotifications: { status: NotificationStatus.Pending },
    adminRecordDriveUpload: { status: TaskStatus.Pending },
  };

  batch.set(newAdministrationRef, newAdministrationData);

  const prescriptionRef = doc(
    db,
    "patients",
    patient.id,
    "prescriptions",
    prescription.id,
  );

  batch.update(prescriptionRef, {
    nextInjectionDate: updatedNextInjectionDate,
    appointmentDateTime: null,
    appointmentServiceLocation: patient.defaultServiceLocation,
    appointmentComments: null,
    hasConfirmedAppointment: false,
    followUpDate: null,
    workflowStatus: "Default",
    // One-time injections should be inactive after given.
    ...(prescription.orderType === "one-time" && {
      status: "inactive",
    }),
  });

  // Reference to the comments subcollection within the prescription document
  const commentsRef = collection(
    db,
    "patients",
    patient.id,
    "prescriptions",
    prescription.id,
    "comments", // Assuming the comments are in a subcollection under the prescription
  );

  // Query to find comments with 'hideAfterNextAdministration' flag set to true
  const q = query(
    commentsRef,
    where("hideAfterNextAdministration", "==", true),
  );

  // Get the matching comments
  const querySnapshot = await getDocs(q);

  // Loop through the results and update the hideAfterDate field
  querySnapshot.forEach((commentDoc) => {
    // Update the hideAfterDate field to the current server timestamp
    batch.update(commentDoc.ref, {
      hideAfterDate: serverTimestamp(),
    });
  });

  await batch.commit();

  // We do not want to return this promise because it will cause the administer function to hang.
  axios.post("/api/post-administration-tasks", {
    patient,
    prescription,
    administration: newAdministrationData,
  });
}

export function useMostRecentAdministration({
  patientId,
  prescriptionId,
  handleResult,
}: {
  patientId: string;
  prescriptionId: string;
  handleResult: (administration: Administration) => void;
}) {
  useEffect(() => {
    if (!patientId || !prescriptionId) {
      return;
    }

    let didCancel = false;

    const fetchData = async () => {
      try {
        const q = query(
          collection(db, "patients", patientId, "administrations"),
          where("prescriptionId", "==", prescriptionId),
          orderBy("administrationDate", "desc"),
          limit(1),
        );

        const querySnapshot = await getDocs(q);

        if (didCancel) {
          return;
        }

        if (!querySnapshot.empty) {
          handleResult(querySnapshot.docs[0].data() as Administration);
        } else {
          handleResult(null);
        }
      } catch (error) {
        captureException(error);
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [patientId, prescriptionId, handleResult]);
}

export function useAdministrations(
  handleUpdate: (administrations: Administration[]) => void,
  administrationDateQueryOptions?: DateQueryOptions,
) {
  useEffect(() => {
    let didCancel = false;

    const fetchData = () => {
      let q = query(collectionGroup(db, "administrations"));

      if (administrationDateQueryOptions) {
        q = query(
          collectionGroup(db, "administrations"),
          where(
            "administrationDate",
            administrationDateQueryOptions.operator,
            administrationDateQueryOptions.value,
          ),
        );
      }
      const unsubscribe = onSnapshot(
        q,
        (administrationsSnapshot) => {
          const administrations: Administration[] = [];
          administrationsSnapshot.forEach((administrationDoc) => {
            const administrationData = administrationDoc.data();
            administrations.push({
              ...(administrationData as Administration),
              id: administrationDoc.id,
            });
          });

          if (!didCancel) {
            handleUpdate(administrations);
          }
        },
        (error) => {
          console.error(error);
          captureException(error);
        },
      );

      return unsubscribe;
    };

    const unsubscribe = fetchData();
    return () => {
      didCancel = true;
      unsubscribe(); // unsubscribe from the Firestore listener when component unmounts or inputs change
    };
  }, [administrationDateQueryOptions, handleUpdate]);
}

export async function getAllPrescriptionAdministrations(
  prescriptionId: string,
) {
  const q = query(
    collectionGroup(db, "administrations"),
    where("prescriptionId", "==", prescriptionId),
    orderBy("administrationDate", "desc"),
  );

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
}

export function usePrescriptionAdministrations({
  patientId,
  prescriptionId,
  handleUpdate,
}: {
  patientId: string;
  prescriptionId: string;
  handleUpdate: (administrations: Administration[]) => void;
}) {
  useEffect(() => {
    if (!patientId || !prescriptionId) {
      return;
    }

    let didCancel = false;

    const fetchData = () => {
      const q = query(
        collection(db, "patients", patientId, "administrations"),
        where("prescriptionId", "==", prescriptionId),
      );

      const unsubscribe = onSnapshot(
        q,
        (administrationsSnapshot) => {
          const administrations: Administration[] = [];
          administrationsSnapshot.forEach((administrationDoc) => {
            if (administrationDoc.exists()) {
              const administrationData = administrationDoc.data();
              administrations.push({
                ...(administrationData as Administration),
                id: administrationDoc.id,
                administrationDate:
                  administrationData.administrationDate.toDate(),
                productExpirationDate:
                  administrationData.productExpirationDate.toDate(),
                lastBilledDate: administrationData.lastBilledDate
                  ? administrationData.lastBilledDate.toDate()
                  : null,
              });
            }
          });

          if (!didCancel) {
            handleUpdate(administrations);
          }
        },
        (error) => {
          captureException(error);
        },
      );

      return unsubscribe;
    };

    const unsubscribe = fetchData();
    return () => {
      didCancel = true;
      unsubscribe(); // unsubscribe from the Firestore listener when component unmounts or inputs change
    };
  }, [patientId, prescriptionId, handleUpdate]);
}

export async function administrationDataSync() {
  const q = query(collectionGroup(db, "administrations"));
  const administrationsQuerySnapshot = await getDocs(q);
  administrationsQuerySnapshot.forEach(async (administrationSnapshot) => {
    const administrationData = administrationSnapshot.data() as Administration;
    const patientId = administrationData.patientId;
    const prescriptionId = administrationData.prescriptionId;
    const prescriptionRef = doc(
      db,
      "patients",
      patientId,
      "prescriptions",
      prescriptionId,
    );
    const prescriptionSnapshot = await getDoc(prescriptionRef);
    if (prescriptionSnapshot.exists()) {
      const prescriptionData = prescriptionSnapshot.data();
      await updateDoc(administrationSnapshot.ref, {
        prescription: {
          patient: {
            firstName: prescriptionData.patient.firstName,
            lastName: prescriptionData.patient.lastName,
            birthdate: prescriptionData.patient.birthdate,
            gender: prescriptionData.patient.gender,
            id: prescriptionData.patient.id,
          },
          prescriber: {
            firstName: prescriptionData.prescriber.firstName,
            lastName: prescriptionData.prescriber.lastName,
            NPI: prescriptionData.prescriber.NPI,
            email: prescriptionData.prescriber.email,
            fax: prescriptionData.prescriber.fax,
            notificationPrefs: prescriptionData.prescriber.notificationPrefs,
            id: prescriptionData.prescriber.id,
          },
          medication: {
            name: prescriptionData.medication.name,
            strength: prescriptionData.medication.strength,
            id: prescriptionData.medication.id,
          },
          doseAmount: prescriptionData.doseAmount,
          doseUnits: prescriptionData.doseUnits,
          frequencyValue: prescriptionData.frequencyValue,
          frequencyUnits: prescriptionData.frequencyUnits,
          orderType: prescriptionData.orderType,
          id: prescriptionData.id,
        },
      });
    }
  });
}

export function useAdministrationById({
  patientId,
  administrationId,
  handleUpdate,
}: {
  patientId: string;
  administrationId: string;
  handleUpdate: (administration: Administration | null) => void;
}) {
  useEffect(() => {
    if (!patientId || !administrationId) {
      throw new Error("Missing patientId or administrationId");
    }

    let didCancel = false;

    const fetchData = () => {
      const administrationDocRef = doc(
        db,
        "patients",
        patientId,
        "administrations",
        administrationId,
      );

      const unsubscribe = onSnapshot(
        administrationDocRef,
        (administrationDoc) => {
          if (administrationDoc.exists()) {
            const administrationData = administrationDoc.data();
            if (!didCancel) {
              handleUpdate({
                ...(administrationData as Administration),
                administrationDate:
                  administrationData.administrationDate.toDate(),
                productExpirationDate:
                  administrationData.productExpirationDate.toDate(),
                lastBilledDate: administrationData.lastBilledDate
                  ? administrationData.lastBilledDate.toDate()
                  : null,
              });
            }
          } else {
            handleUpdate(null);
          }
        },
      );

      return unsubscribe;
    };

    const unsubscribe = fetchData();
    return () => {
      didCancel = true;
      unsubscribe(); // unsubscribe from the Firestore listener when component unmounts or inputs change
    };
  }, [handleUpdate, administrationId, patientId]);
}

export async function updateHistoricalAdministration(
  patientId: string,
  administrationId: string,
  updatedFields: { [fields: string]: any },
) {
  const administrationRef = doc(
    db,
    "patients",
    patientId,
    "administrations",
    administrationId,
  );

  return updateDoc(administrationRef, updatedFields);
}

export function createAdministrationLink({
  patientId,
  prescriptionId,
  administrationId,
}: {
  patientId: string;
  prescriptionId: string;
  administrationId: string;
}) {
  return `${
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://injections.rexrx.com"
  }/patients/${patientId}/prescriptions/${prescriptionId}/administrations/${administrationId}`;
}

export function downloadAdministrationRecord(administration: Administration) {
  const filename = sanitize(
    `${administration.prescription.patient.firstName} ${
      administration.prescription.patient.lastName
    } - ${administration.prescription.medication.name} ${
      administration.prescription.medication.strength
    } - ${format(ensureValueIsDate(administration.administrationDate), "P")}`,
    { replacement: "-" },
  );
  return pdfMake
    .createPdf(createAdministrationDocDefinition(administration, true))
    .download(filename);
}

export function printAdministrationRecord(administration: Administration) {
  return pdfMake
    .createPdf(createAdministrationDocDefinition(administration, true))
    .print();
}

export type Administration = {
  id: string;
  administrationDate: Date | string;
  productExpirationDate: Date | string;
  administeredBy: string;
  administrationSite: string;
  needleLength: string;
  productLotNumber: string;
  receivingSignature: string;
  receivingSignatureType: string;
  caregiverName?: string;
  prescriptionNumber?: string;
  lastBilledDate?: Date | Timestamp | string;
  injectionWasDue: Date;
  injectionNowDue: Date | null;
  administrationComments: string;
  patientId: string;
  prescriptionId: string;
  prescription: {
    patient: {
      firstName: string;
      lastName: string;
      birthdate: string;
      gender: string;
      id: string;
    };
    prescriber: {
      firstName: string;
      lastName: string;
      NPI: string;
      email: string[];
      fax: string;
      notificationPrefs: {
        email: boolean;
        fax: boolean;
      };
      id: string;
    };
    medication: {
      name: string;
      strength: string;
      id: string;
    };
    doseAmount: string;
    doseUnits: string;
    frequencyValue: string;
    frequencyUnits: string;
    orderType: string;
    id: string;
  };
};
