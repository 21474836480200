import { parseISO } from "date-fns";

/**
 *
 *
 * @date 2022-12-28
 * @export
 * @param {*} value
 * @param {function} [isValueAllowed] Function which should return true if a particular value is allowed, otherwise should return false to throw an error.
 * @returns
 */
export function ensureValueIsDate(
  value: any,
  isValueAllowed?: (value: unknown) => boolean
): Date {
  if (value instanceof Date) {
    return value;
  } else if (value?.toDate && typeof value.toDate === "function") {
    return value.toDate();
  } else if (typeof value === "string" && parseISO(value) instanceof Date) {
    return parseISO(value);
  } else if (
    value &&
    typeof value === "object" &&
    typeof value.seconds === "number" &&
    typeof value.nanoseconds === "number"
  ) {
    // Convert to total milliseconds by multiplying seconds by one thousand and dividing nanoseconds by one million
    return new Date(value.seconds * 1e3 + value.nanoseconds / 1e6);
  } else if (typeof isValueAllowed === "function" && isValueAllowed(value)) {
    return value;
  }
  throw new Error(
    "Value is neither JS Date object, Firestore Timestamp, nor an acceptable alternative value."
  );
}
