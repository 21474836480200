import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { createProfileLink } from "models/Patient";
import Link from "next/link";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { Highlight, useHits } from "react-instantsearch";

function PatientHitsList({ closeSearchModal }) {
  const { hits } = useHits();
  return (
    <List disablePadding>
      {hits.length ? (
        hits.map((hit) => (
          <ListItem
            key={hit.objectID}
            disablePadding
            sx={{
              borderBottom: "1px solid rgb(224, 227, 231)",
            }}
          >
            <Link
              href={createProfileLink(hit.objectID)}
              passHref
              legacyBehavior
            >
              <ListItemButton component="a" onClick={closeSearchModal}>
                <ListItemText
                  primary={
                    <Fragment>
                      <Highlight hit={hit} attribute="firstName" />{" "}
                      <Highlight hit={hit} attribute="lastName" />
                    </Fragment>
                  }
                  primaryTypographyProps={{
                    fontSize: "14px",
                    sx: {
                      color: (theme) =>
                        hit.status === "active"
                          ? theme.palette.success.dark
                          : theme.palette.error.dark,
                    },
                  }}
                  secondary={
                    <Fragment>
                      <Typography display="inline-block" variant="body">
                        Status:{" "}
                        {hit?.status?.charAt(0)?.toUpperCase() +
                          hit?.status?.slice(1)}
                      </Typography>
                      {" - "}
                      <Typography display="inline-block" variant="body">
                        DOB:
                      </Typography>{" "}
                      <Highlight
                        hit={hit}
                        attribute="birthdateFormattedString"
                      />
                      <br />
                      <Typography display="inline-block" variant="body">
                        Default Location:{" "}
                        {hit?.defaultServiceLocation.description}
                      </Typography>{" "}
                    </Fragment>
                  }
                  secondaryTypographyProps={{
                    fontSize: "12px",
                    sx: {
                      color: (theme) =>
                        hit.status === "active"
                          ? theme.palette.success.dark
                          : theme.palette.error.dark,
                    },
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))
      ) : (
        <ListItem>
          <ListItemText
            primary="No matching patients..."
            primaryTypographyProps={{ fontSize: "14px" }}
          />
        </ListItem>
      )}
    </List>
  );
}

PatientHitsList.propTypes = {
  closeSearchModal: PropTypes.func.isRequired,
};

export default PatientHitsList;
