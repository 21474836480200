import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { alpha } from "@mui/system";
import { Fragment, useEffect, useRef, useState } from "react";
import { Configure, Index, useSearchBox } from "react-instantsearch";
import PatientHitsList from "./PatientHitsList";
import PrescriptionsHitsList from "./PrescriptionHitsList";

function SearchModal() {
  const [isOpen, setIsOpen] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const inputRef = useRef();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const searchInputRef = inputRef?.current;
    if (isOpen) {
      searchInputRef?.focus();
    } else {
      searchInputRef?.blur();
    }

    return () => {
      searchInputRef?.blur();
    };
  }, [isOpen]);

  const { query, refine, clear } = useSearchBox();

  const [searchQuery, setSearchQuery] = useState("");

  function handleSearchInputChange(event) {
    const inputValue = event.target.value;
    setSearchQuery(inputValue);
    const dateRegex =
      /(?<month>[0-9]{2})[- \/.]?(?<day>[0-9]{2})[- \/.]?(?<year>[0-9]{4})/;
    const match = inputValue.match(dateRegex);
    if (match) {
      const { month, day, year } = match.groups;
      refine(`${month}/${day}/${year}`);
    } else {
      refine(event.target.value);
    }
  }

  return (
    <Fragment>
      <Button
        onClick={handleOpen}
        variant="contained"
        startIcon={<SearchIcon />}
        disableElevation
        sx={(theme) => ({
          justifyContent: "flex-start",
          textTransform: "none",
          backgroundColor: alpha(theme.palette.common.white, 0.15),
          "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
          },
          marginRight: theme.spacing(2),
          marginLeft: 0,
          width: "100%",
          [theme.breakpoints.up("sm")]: { maxWidth: 300 },
          [theme.breakpoints.up("md")]: { marginLeft: theme.spacing(3) },
        })}
      >
        Search...
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        keepMounted
        sx={{
          "& .MuiDialog-paper": {
            height: { sm: "600px" },
          },
        }}
      >
        <Box padding={1} borderBottom="1px solid rgb(224, 227, 231)">
          <Stack direction="row" alignItems="center" sx={{ paddingX: 1 }}>
            <SearchIcon color="primary" />
            <InputBase
              inputRef={inputRef}
              onChange={handleSearchInputChange}
              sx={{
                ml: 2,
                mr: 1,
                flex: 1,
                fontSize: "1.2em",
                height: "56px",
              }}
              placeholder="Search..."
              value={searchQuery}
              inputProps={{ "aria-label": "search" }}
            />
            {searchQuery && (
              <IconButton
                onClick={() => {
                  clear();
                  setSearchQuery("");
                  inputRef?.current?.focus();
                }}
                sx={{ marginRight: 1 }}
              >
                <CloseIcon />
              </IconButton>
            )}

            <Button
              onClick={handleClose}
              disableElevation
              variant="contained"
              sx={{
                textTransform: "none",
                paddingY: "1px",
                paddingX: "6px",
                minWidth: "unset",
                lineHeight: "unset",
              }}
            >
              esc
            </Button>
          </Stack>
        </Box>
        <Box paddingBottom={2} paddingX={1} position="relative">
          <Box
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            backgroundColor="white"
            zIndex={1}
            display={query ? "none" : "initial"}
            paddingX={2}
          >
            <Typography
              textAlign="center"
              display="block"
              variant="caption"
              fontSize="15px"
              paddingTop={2}
            >
              Search for patients or prescriptions by first name, last name, or
              birthdate (02061989 OR 02/06/1989).
            </Typography>
          </Box>
          <Box display={query ? "initial" : "none"}>
            <Index indexName="patients">
              <Typography
                display="block"
                variant="caption"
                fontSize="15px"
                paddingTop={2}
              >
                Patients
              </Typography>
              <Configure hitsPerPage={3} />
              <PatientHitsList closeSearchModal={handleClose} />
            </Index>
            <Index indexName="prescriptions">
              <Typography
                display="block"
                variant="caption"
                fontSize="15px"
                paddingTop={2}
              >
                Prescriptions
              </Typography>
              <PrescriptionsHitsList closeSearchModal={handleClose} />
              <Configure hitsPerPage={3} />
            </Index>
          </Box>
        </Box>
      </Dialog>
    </Fragment>
  );
}

export default SearchModal;
