import { Box, Drawer, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import DrawerItems from "./DrawerItems";

function ResponsiveDrawer({
  drawerWidth,
  toggleDrawer,
  isDrawerOpen,
  additionalDrawerItems,
}) {
  const theme = useTheme();

  return (
    <Box
      component="nav"
      sx={{
        width: { md: drawerWidth },
        flexShrink: { sm: 0 },
        // The fixed AppBar changes height at the small breakpoint. If we don't adjust the size of this, than the main content can be hidden underneath the AppBar, or alternatively, the extra height will spill into the main content.
        height: {
          xs: theme.spacing(13),
          sm: theme.spacing(14),
        },
      }}
    >
      <Drawer
        variant="temporary"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            justifyContent: "space-between",
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {additionalDrawerItems}
        <DrawerItems />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            paddingTop: "104px",
            [theme.breakpoints.up("sm")]: {
              paddingTop: "112px",
            },
            justifyContent: "space-between",
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
        open
      >
        {additionalDrawerItems}
        <DrawerItems />
      </Drawer>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  drawerWidth: PropTypes.string.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
  additionalDrawerItems: PropTypes.node,
};

export default ResponsiveDrawer;
