import { Patient } from "models/Patient";
import { Prescription } from "models/Prescription";
import { Administration } from "models/Administration";

export enum NotificationType {
  Email = "Email",
  Fax = "Fax",
}

export enum NotificationContext {
  AdministrationRecord = "Administration Record",
  AdministrationRecordManual = "Administration Record - Manual",
}

export enum NotificationStatus {
  Pending = "Pending",
  Completed = "Completed",
  Failed = "Failed",
  Inapplicable = "Inapplicable",
  Resolved = "Resolved",
}

export type EmailNotificationData = {
  type: NotificationType.Email;
  initialOptions: {
    from: string;
    to: string[];
    subject: string;
    template: string;
    attachment?: {
      data: Buffer;
      filename: string;
    };
  };
};
export type FaxNotificationData = {
  type: NotificationType.Fax;
  initialOptions: {
    recipientName: string;
    faxNumber: string;
    senderName: string;
    filePath?: string;
  };
};

export type Notification = {
  id?: string;
  patient: Pick<
    Patient,
    "firstName" | "lastName" | "birthdate" | "gender" | "id"
  >;
  prescription: Prescription;
  administration?: Administration;
  context: NotificationContext;
  status: NotificationStatus;
  error: { name: string; message: string } | null;
  originalMessageId: string | null;
  senderName?: string;
} & (EmailNotificationData | FaxNotificationData);

export type NotificationAttempt = {
  id?: string;
  messageId: string | null;
  attemptDate: Date;
  status: NotificationStatus;
  error: {
    name: string;
    message: string;
  } | null;
};
