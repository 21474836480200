import { PersonAdd as PersonAddIcon } from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import Link from "next/link";
import PropTypes from "prop-types";

function AdministratorsDrawerItems({ useDense }) {
  return (
    <List dense={useDense}>
      <ListSubheader>Actions</ListSubheader>
      <Link
        href="/administrators/add-new-administrator"
        passHref
        legacyBehavior
      >
        <ListItemButton>
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary="Add New Administrator" />
        </ListItemButton>
      </Link>
    </List>
  );
}

AdministratorsDrawerItems.propTypes = {
  useDense: PropTypes.bool,
};

AdministratorsDrawerItems.defaultProps = {
  useDense: false,
};

export default AdministratorsDrawerItems;
